import { NavLink } from 'react-router-dom';
import './navbar.scss';
import { BsCalendar3, BsQuestionLg, BsFillTelephoneFill } from 'react-icons/bs';
import { AiOutlineHome } from 'react-icons/ai';
import { GiVacuumCleaner } from "react-icons/gi";



import logo from '../../assets/logoCT.png';

const Navbar = () => {
  return (
    <div className="navbar">
      <NavLink to='/' className="logo">
        <img src={logo} alt="Clean Team Logo" />
      </NavLink>

      <nav>
        <NavLink to="/">
          <AiOutlineHome />
          Dubinsko čišćenje
        </NavLink>

        <NavLink to="visokotlacno-ciscnje">
          <GiVacuumCleaner />
          Visokotlačno čišćenje
        </NavLink>

        {/* <hr /> */}

        <a href="https://wa.me/385997448333">
          <BsCalendar3 />
          Rezerviraj 
          termin
        </a>

        <a href="tel:+385997448333" className='phone'>
          <BsFillTelephoneFill/>
          Nazovi
        </a>
      </nav>
    </div>
  );
};

export default Navbar;
