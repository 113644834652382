import "./pressureWashPage.scss";

import { Link } from "react-router-dom";

import image1 from "../../assets/visokotlacnoCiscenje3.webp";
import image2 from "../../assets/visokotlacnoCiscenje2.jpeg";

import {
  BsCalendar3,
  BsFillCreditCardFill,
  BsFillPatchCheckFill,
  BsFillPlayFill,
} from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet";

const PressureWashPage = () => {
  return (
    <>
      <Helmet>
        <title>Visokotlačno čišćenje | Clean Team</title>
      </Helmet>

      <div className="homePage">
        <div className="headerImage">
          <img src={image1} alt="Kemijsko čišćenje" />
          <div className="imageOverlay"></div>
          <div className="imageText">
            <h2>
              Clean Team
              <br />
              Visokotlačno čišćenje
            </h2>
            <div className="text">
              <p>Kontaktirajte nas za besplatnu ponudu i rezervaciju termina</p>
            </div>
            <a href="https://wa.me/385997448333">
              <BsCalendar3 />
              Rezerviraj termin
            </a>
          </div>
        </div>

        <div className="homePageAbout">
          <h2>
            Pružamo usluge visokotlačnog čišćenja na području grada Zagreba i
            zagrebačke županije
          </h2>
          <div className="homePageAbout__text">
            <p>
              Pružamo profesionalne usluge visokotlačnog čišćenja za vaše domove
              i poslovne prostore na području Zagreba i okolice. Bez obzira
              trebate li očistiti dvorište, kamen, terasu, pločnike ili nešto
              drugo, mi smo tu da vam pomognemo.
            </p>
          </div>
          {/* <h3>
            <BsFillPlayFill />
            Specijalizirani smo za obnovu i osvježavanje Vašeg namještaja kako
            bi izgledao kao nov.
          </h3> */}
        </div>

        {/* <div className="homePageServices">
          <div className="servicesSwiper">
            <div className="imageSwiper">
              <div className="image">
                <img src={image1} alt="Kemijsko čišćenje fotelje" />
                <div className="imageOverlay"></div>
              </div>
               <div className="image">
                <img src={image5} alt="Kemijsko čišćenje madraca" />
                <div className="imageOverlay"></div>
              </div> 
            </div>
          </div>
          <div className="servicesContent">
            <p>
              Sigurnost i higijena: Naši postupci čišćenja su sigurni za Vaše
              zdravlje i okoliš. Koristimo ekološki prihvatljive proizvode koji
              su neškodljivi za Vas i Vaše kućne ljubimce.
            </p>
            <p>
              Povjerenje i pouzdanost: Naša tvrtka ima dugogodišnje iskustvo u
              industriji čišćenja. Možete se osloniti na našu stručnost i
              posvećenost pružanju vrhunske usluge.
            </p>
          </div>
        </div> */}

        <div className="homePageWhyUs">
          <div className="whyUsContent">
            <h2>Očuvanje kvalitete</h2>
            <p>
              Kada se odlučujete za visokotlačno čišćenje, važno je raditi s
              pouzdanim i iskusnim timom. Evo zašto bismo trebali biti vaš
              izbor:
            </p>
            <ul>
              <li>
                <BsFillPlayFill />
                Profesionalni pristup i visoka kvaliteta usluge
              </li>
              <li>
                <BsFillPlayFill />
                Koristimo visokokvalitetnu opremu i ekološki prihvatljive
                deterdžente
              </li>
              <li>
                <BsFillPlayFill />
                Fleksibilnost u terminima i pristupačne cijene
              </li>
              <li>
                <BsFillPlayFill />
                Brza i učinkovita usluga koja štedi vaše vrijeme
              </li>
            </ul>
          </div>
          <div className="whyUsImage">
            <img src={image2} alt="Čišćenje" />
          </div>
        </div>

        <div className="homePageAbout">
          <h2>Pouzdanost i profesionalnost</h2>
          <div className="homePageAbout__text">
            <p>
              Kontaktirajte nas danas kako biste dogovorili termin za
              profesionalno visokotlačno čišćenje.
            </p>
          </div>
          <h3>
            <BsFillPlayFill />
            Rezervirajte dubinsko čišćenje putem naše web stranice ili telefona.
          </h3>
        </div>
        <section>
          <div className="column">
            <BsFillCreditCardFill />
            <h3>
              Plaćanje karticama <br /> i gotovinom
            </h3>
          </div>
          <div className="column">
            <BsFillPatchCheckFill />
            <h3>Iskustvo i profesionalnost</h3>
          </div>
          <div className="column">
            <FaHandshake />
            <h3>
              Dolazak na adresu <br /> uključen u cijenu
            </h3>
          </div>
        </section>
      </div>
    </>
  );
};

export default PressureWashPage;
