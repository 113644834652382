import { Link } from 'react-router-dom'
import './successPage.scss'

const SuccessPage = () => {
  return (
    <div className='successPage'>
      <h2>Uspješno ste rezervirali termin za čišćenje</h2>
      <p>Na Vaš email je poslana potvrda s Vašim podacima. Ukoliko bude potrebno naš tim će Vas kontaktirati.</p>
      <Link to='/'>Početna</Link>
    </div>
  )
}

export default SuccessPage